<div class="position-relative">
  <input (selectItem)="selectItem($event)"
         [(ngModel)]="model"
         [inputFormatter]="formatter"
         [ngbTypeahead]="search"
         [resultTemplate]="rt"
         attr.aria-label="{{placeholder}}"
         class="form-control search-field"
         placeholder="{{placeholder}}"
         type="text"
  />

  @if (searching) {
    <fa-icon [icon]="faSpinner" size="lg" [animation]="'spin'"
             class="typeahead-icon"></fa-icon>
  } @else {
    <fa-icon [icon]="faSearch" size="lg"
             class="typeahead-icon"></fa-icon>
  }
</div>

<ng-template #rt let-r="result">
  <div class="clickable">
    <div class="typeahead-image float-start">
      <img alt="{{r.name}}" fill src="{{r.imageURL}}"/>
    </div>

    @if (r.type === 'film') {
      <div>
        {{ r.name }} <span class="text-muted">({{ r.year }})</span>
        <div class="text-muted">
          @if (r.originalTitle !== r.name) {
            <small>{{ r.originalTitle }}</small>
          }
        </div>
      </div>
    }

    @if (r.type === 'person') {
      <div>
        {{ r.name }}
        <div class="text-muted">
          <small>
            {{ renderRoles(r) }}
          </small>
        </div>
      </div>
    }
  </div>
</ng-template>
