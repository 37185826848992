import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { TypeaheadHit } from './typeaheadHit';

@Injectable({
  providedIn: 'root'
})
export class TypeaheadService {

  private typeaheadUrl = '/api/typeahead';

  constructor(private http: HttpClient) {
  }

  search(searchFor: string, includeFilms: boolean, includePersons: boolean): Observable<TypeaheadHit[]> {
    return this.http.post<TypeaheadHit[]>(this.typeaheadUrl, {
      searchString: searchFor,
      includeFilms: includeFilms,
      includePersons: includePersons
    });
  }
}
