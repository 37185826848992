import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
  WritableSignal
} from '@angular/core';
import { TypeaheadHit } from '../typeaheadHit';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { Observable, of, OperatorFunction } from 'rxjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { FormsModule } from '@angular/forms';
import { faSearch, faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { TypeaheadService } from "../typeahead.service";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-film-type-ahead',
  templateUrl: './film-type-ahead.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, FontAwesomeModule, NgbTypeahead]
})
export class FilmTypeAheadComponent implements OnInit {
  model: WritableSignal<any> = signal('');
  searching = false;
  searchFailed = false;
  @Input()
  includeFilms = true;
  @Input()
  includePersons = false;
  placeholder!: string;
  @Output() public selected: EventEmitter<any> = new EventEmitter();
  protected readonly faSpinner = faSpinner;
  protected readonly faSearch = faSearch;

  public constructor(private typeaheadService: TypeaheadService) {
  }

  formatter = (x: { name: string }) => x.name;

  search: OperatorFunction<string, readonly string[] | TypeaheadHit[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.typeaheadService.search(term, this.includeFilms, this.includePersons).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    );

  ngOnInit(): void {
    this.placeholder = this._placeholder();
  }

  renderRoles(person: TypeaheadHit) {
    const roles = [];
    if (person.director) {
      roles.push('regissör');
    }
    if (person.actor) {
      roles.push('skådespelare');
    }
    if (person.writer) {
      roles.push('författare');
    }
    return roles.join(' | ');
  }

  selectItem($event: any) {
    this.selected.emit($event.item);
    this.model.set('');
  }

  private _placeholder(): string {
    if (this.includeFilms && !this.includePersons) {
      return 'Sök filmtitel';
    } else if (this.includePersons && !this.includeFilms) {
      return 'Sök person';
    }
    return 'Sök filmtitel eller person';
  }
}
